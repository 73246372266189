.App {
  text-align: center;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  object-fit: cover;
  max-width: 500px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: auto;
  border: 25px;
  width: auto;
  /* max-width: 100px; */
  object-fit: cover;
}

.App-header {
  background-color:white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.Red-Corvette {
  max-width: 100px;
  justify-content: center;
}

.Button-Group {
  margin: auto; 
  width:  50%;
  max-width: 400px;
}
